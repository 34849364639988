<template lang="pug">
div
  template(v-if="dsAllowed && (backgroundSrc || isImage)")
    color-input(
      :size="size"
      :layout="layout"
      :label="label"
      :backgroundSrc="src"
      :editMobile="editMobile"
      @click="handleImageChange"
    )
  template(v-else-if="dsAllowed && !backgroundSrc")
    color-input(
      :size="size"
      :layout="layout"
      :label="label"
      :isGlobalStyle="isGlobalStyle"
      :isSvg="isSvg"
      :coloringStyle="coloringStyle"
      :backgroundSrc="src"
      :coloringType="coloringType"
      :colorValue="colorValue"
      :editMobile="editMobile"
      :deviceSelector="deviceSelector"
      @click="handleColorChange"
    )
    RangeInput.mt-3(
      v-if="isGradient"
      :min="0"
      :max="360"
      unit="°"
      v-model="gradientValue"
      :label="$t('gradientDirection')"
    )
  template(v-else)
    om-color-input-v2(
      :property="property"
      :label="label"
      :typeOfComponent="typeOfComponent"
      :alpha="alpha"
      :gradient="gradient"
      :onlySolid="onlySolid"
      :colorInstance="colorInstance"
      :editMobile="editMobile"
    )
</template>
<script>
  import { get as _get } from 'lodash-es';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import ColorInput from '@/components/Editor/ColorInput/ColorInput.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import { isSvgUrl } from '@/editor/util';
  import { getNameFromURL } from '@om/template-properties/src/imageReplace';
  import OmColorInputV2 from './OmColorInputV2.vue';
  import ColorComponentsFactory from '../../../utils/color-components/components';

  const frame = () => {
    const workspace = document.getElementById('workspaceFrame');
    return workspace;
  };

  const frameStore = () => {
    const _frame = frame();
    return _frame && _frame.contentWindow && _frame.contentWindow.om
      ? _frame.contentWindow.om.store
      : null;
  };

  export default {
    components: {
      OmColorInputV2,
      RangeInput,
      ColorInput,
    },
    mixins: [themeColorsMixin],
    props: {
      property: { type: String, required: false, default: '$device.background.color' },
      label: { type: String, required: false },
      typeOfComponent: { type: String, default: 'global' },
      alpha: { type: Boolean, default: true },
      gradient: { type: Object, required: false },
      onlySolid: { type: Boolean, default: false },
      editMobile: { type: Boolean, default: false },
      // for DS color input
      size: {
        type: String,
        default: 'sm',
        options: ['sm', 'lg'],
        validator: (value) => {
          return ['sm', 'lg'].includes(value);
        },
      },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      backgroundSrc: {
        type: String,
        required: false,
      },
      dsAllowed: {
        type: Boolean,
        default: false,
      },
      isImage: {
        type: Boolean,
        default: false,
      },
      deviceSelector: { type: Boolean, default: false },
    },
    data() {
      return {
        colorInstance: null,
      };
    },
    computed: {
      ...mapState([
        'selectedElement',
        'selectedColumn',
        'selectedRow',
        'selectedPage',
        'globalStyle',
        'mobilePreview',
        'themeKit',
      ]),
      gradientValue: {
        get() {
          return Number(this.colorInstance.getLinearDirection());
        },
        set(v) {
          this.colorInstance.setLinearDirection(v);
          this.$store.commit('handleColorInstanceToStore', this.colorInstance);
          window.om.bus.$emit('userInputChange', {
            property: this.colorInstance.getLinearDirectionProperty(),
            value: v,
          });
        },
      },
      isGradient() {
        return this.colorInstance.getColoringType() === 'gradient';
      },
      isLogoImage() {
        if (!this.themeKit?.logo?.current) return false;

        const logoName = getNameFromURL(this.themeKit.logo.current);
        const desktopUrl = this.selectedElement?.desktop?.background?.imageUrl;
        const mobileUrl = this.selectedElement?.mobile?.background?.imageUrl;
        const desktopName = getNameFromURL(desktopUrl);
        const mobileName = getNameFromURL(mobileUrl);

        return logoName === desktopName || logoName === mobileName;
      },
      isGlobalStyle() {
        return this.property?.includes?.('globalStyle');
      },
      getImageSrcFromId() {
        const imageId =
          this.colorInstance?.getImageId?.() ||
          _get(this.selectedElement, 'desktop.background.imageId');
        if (!imageId) return;

        const image = frameStore().getters.findImage(imageId);

        return (image && image.url) || '';
      },
      isSvg() {
        if (this.backgroundSrc) return isSvgUrl(this.backgroundSrc);

        return (
          (this.colorInstance &&
            this.colorInstance.getImageSrc?.() &&
            isSvgUrl(this.colorInstance.getImageSrc())) ||
          (this.getImageSrcFromId && isSvgUrl(this.getImageSrcFromId))
        );
      },
      coloringStyle() {
        return this.colorInstance && this.colorInstance.getColorStyle();
      },
      src() {
        return (
          this.backgroundSrc ||
          (this.colorInstance && this.colorInstance.getImageSrc?.()) ||
          this.getImageSrcFromId
        );
      },
      coloringType() {
        return this.colorInstance && this.colorInstance.getColoringType();
      },
      colorValue() {
        if (!this.colorInstance) return;

        const color = this.colorInstance.getColor();
        if (Number.isInteger(color)) {
          return this.getColorsMethod()[color];
        }
        return color;
      },
      getCorrectElement() {
        if (this.dsAllowed) {
          if (this.typeOfComponent === 'column') return this.selectedColumn;
          if (this.typeOfComponent === 'row') return this.selectedRow;

          if (this.property.includes('selectedPage') || this.typeOfComponent === 'page') {
            return this.selectedPage;
          }
        }

        return this.isGlobalStyle ? this.globalStyle : this.selectedElement;
      },
    },

    watch: {
      mobilePreview() {
        this.createColorInstance();
      },
    },
    created() {
      this.createColorInstance();

      this.$bus.$on('historyChanged', this.rebuildColorInstance);
      this.$bus.$on('rebuild-color-instance', this.rebuildColorInstance);
      this.$bus.$on('updateValueInColorInstance', this.updateValueInColorInstance);
    },
    methods: {
      ...mapMutations([
        'setImageManagerDestination',
        'setImageManagerLogoUpload',
        'showImageManager',
        'resetColorInstance',
      ]),
      ...mapActions(['showColorPicker']),
      handleImageChange() {
        this.setImageManagerDestination({
          targetAttr: 'selectedElement',
          addElement: false,
          mobileAlternative: false,
        });
        if (this.isLogoImage) this.setImageManagerLogoUpload(true);
        this.showImageManager();
        this.resetColorInstance();
      },
      handleColorChange(e) {
        e.preventDefault();
        e.stopPropagation();
        const elementRect = e.srcElement.getBoundingClientRect();

        const params = {
          top: elementRect.top,
          left: elementRect.left,
          property: this.colorInstance.getColorProperty(),
          value: this.colorValue,
          component: this.typeOfComponent,
          alpha: this.alpha,
          colorInstance: this.colorInstance,
          calculateSidebarPosition: true,
        };

        this.showColorPicker(params);
      },
      rebuildColorInstance() {
        if (!this.colorInstance) return;
        this.$nextTick(() => {
          this.colorInstance = null;
          this.createColorInstance();
        });
      },
      updateValueInColorInstance({ property, value }) {
        if (!this.colorInstance) return;

        if (property.includes('background.linearDirection')) {
          this.colorInstance.setLinearDirection(value);
          this.$store.commit('handleColorInstanceToStore', this.colorInstance);
        }
      },
      createColorInstance() {
        if (!this.property) return;
        if (this.dsAllowed && (this.backgroundSrc || this.isImage)) return;

        /*
        somewhere in the code we pass whole path with selectedElement but for the SketchV2 is not necessary
        */
        const { element, replacedPath } = this.getCorrectCtx();

        this.colorInstance = new ColorComponentsFactory(
          this.typeOfComponent,
          element,
          replacedPath,
          this.getColorsMethod(),
          this.isGlobalStyle,
        );
      },
      getCorrectCtx() {
        if (this.dsAllowed) {
          const device = this.mobilePreview ? 'mobile' : 'desktop';
          const replacedPath = this.property
            .replace('selectedElement.', '')
            .replace('$device', device);

          return { element: this.getCorrectElement, replacedPath };
        }

        return {
          element: this.getCorrectElement,
          replacedPath: this.property.replace('selectedElement.', '').replace('$device', 'desktop'),
        };
      },
    },
  };
</script>
